import Footer from "./components/Footer";
import Header from "./components/Header";
import Main from "./components/Main";

function App() {
    return (
        <div className="h-screen w-screen ">
            <Header />
            <div className="flex-grow p-10 min-h-screen">
                <Main />
            </div>
            <Footer />
        </div>
    );
}

export default App;
