import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

function Header() {
    const [isOpen, setIsOpen] = useState(false); // State to control the mobile menu
    const menuRef = useRef(null); // Reference for the menu
    const text = "</kartikpapney>";

    const toggleMenu = () => {
        setIsOpen(prevState => !prevState); 
    };

    const handleClickOutside = (event) => {
        // Check if the clicked element is outside the menu
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsOpen(false); // Close the menu if clicked outside
        }
    };

    useEffect(() => {
        // Add event listener for clicks
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const links = [
        {
            text: "Home",
            link: "./",
        },
        // {
        //     text: "Principles",
        //     link: "./principles",
        // },
        {
            text: "Gears",
            link: "/gears",
        },
        {
            text: "Blogs",
            link: "https://medium.com/@pdt.kartik",
            target: "_blank",
        },
    ];

    return (
        <div className="bg-[#007BFF] text-white">
            <div className="flex justify-between items-center py-2 font-inter  shadow-[#CCCCCC] px-10">
                <h1 className="font-bold text-[24px]">{text}</h1>

                <div className="md:hidden">
                    <button onClick={toggleMenu} className=" focus:outline-none">

                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
                        </svg>
                    </button>
                </div>

                <div className="hidden md:flex space-x-4">
                    {links.map((e) => (
                        <Link
                            className="text-[16px] hover:text-[#0056b3] transition duration-300"
                            key={e.text}
                            to={e.link}
                            target={e.target}
                        >
                            {e.text}
                        </Link>
                    ))}
                </div>
            </div>

            {isOpen && (
                <div
                ref={menuRef}
                className={`absolute right-0 w-full bg-[#f0f0f0] shadow-lg z-10 transition-all duration-300 ease-in-out transform ${isOpen ? 'translate-y-0 opacity-100' : '-translate-y-96 opacity-0'}`}
                style={{ display: isOpen ? 'block' : 'none' }} // Hide menu when closed
            >
                {links.map((e) => (
                    <Link
                        className="block text-[16px] text-[#007BFF] hover:text-[#0056b3] transition duration-300 p-4"
                        key={e.text}
                        to={e.link}
                        target={e.target}
                        onClick={() => setIsOpen(false)} // Close the menu on link click
                    >
                        {e.text}
                    </Link>
                ))}
            </div>
            )}
        </div>
    );
}

export default Header;
