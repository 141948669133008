import React from "react";
import "../index";
const Footer = () => {
    return (
        <div className="pb-10 flex flex-col bg-[#007bff]">

            <footer className="text-white text-center mt-10">
                <div className="container mx-auto">
                    <p>Stay in touch</p>
                    <div>
                        <a
                            href="https://www.linkedin.com/in/kartikpapney/"
                            target="_blank"
                            rel="noreferrer"
                            className="mx-2"
                        >
                            @Linkedin
                        </a>
                        <a href="mailto:pdt.kartik@gmail.com" className="mx-2">
                            @Email
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
